<template>
    <Spinner :loading="!invoiceData.invoice_count" />
    <div
      v-if="invoiceData.invoice_count"
      class="flex flex-col-reverse lg:flex-row items-start gap-6 w-auto min-w-full pb-10 -m-3 sm:mx-0"
    >
      <div class="flex-grow space-y-8 w-full max-w-4xl mx-auto overflow-hidden">
        <Block>
          <div class="flex items-center space-x-4 pb-4 px-2">
            <svg
              class="w-8 h-8 sm:w-12 sm:h-12 flex-shrink-0"
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="23" cy="23" r="23" fill="#75EEA5" fill-opacity="0.37" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.8203 13.4316C17.6352 13.4316 13.4316 17.6352 13.4316 22.8203C13.4316 28.0054 17.6352 32.209 22.8203 32.209C28.0054 32.209 32.209 28.0054 32.209 22.8203C32.209 17.6352 28.0054 13.4316 22.8203 13.4316ZM26.8899 21.2328C26.9648 21.1471 27.0219 21.0473 27.0577 20.9393C27.0935 20.8313 27.1073 20.7172 27.0984 20.6037C27.0894 20.4903 27.0579 20.3797 27.0056 20.2787C26.9533 20.1776 26.8813 20.088 26.7939 20.0151C26.7065 19.9422 26.6053 19.8876 26.4965 19.8544C26.3876 19.8212 26.2732 19.8101 26.16 19.8218C26.0468 19.8334 25.9371 19.8676 25.8373 19.9223C25.7375 19.977 25.6496 20.0511 25.5789 20.1403L21.9088 24.5436L20.0097 22.6436C19.8487 22.4882 19.6331 22.4021 19.4093 22.4041C19.1855 22.406 18.9715 22.4958 18.8132 22.654C18.655 22.8123 18.5652 23.0264 18.5633 23.2501C18.5613 23.4739 18.6473 23.6895 18.8028 23.8505L21.3634 26.4111C21.4472 26.4949 21.5476 26.5603 21.6582 26.6031C21.7687 26.646 21.887 26.6654 22.0054 26.66C22.1239 26.6546 22.2399 26.6246 22.3461 26.5719C22.4523 26.5192 22.5464 26.4449 22.6223 26.3539L26.8899 21.2328Z"
                fill="#3FD37A"
              />
            </svg>
            <div>
              <h3 class="text-base sm:text-lg font-semibold text-theme-secondary">
                Invoices Generated Successfully
              </h3>
            </div>
          </div>
          <div
            class="border-t border-theme-border border-opacity-40 -mx-6 px-2 -mb-5"
          >
            <div
              class="flex flex-col items-start  text-base font-normal text-theme-dark text-opacity-80 leading-loose space-y-6 px-8 pt-6 mb-8"
            >
              <p>Hey {{ invoiceData.receiver_name }},</p>
              <p>
                Your {{ invoiceData.invoice_count > 1 ? 'invoices' : 'invoice' }} have finished exporting 
                and {{ invoiceData.invoice_count > 1 ? 'are' : 'is' }} ready to download. This will 
                only work for the next seven (7) days. Please click the download button below.
              </p>
              <a
                :href="url"
                target="_blank"
                ref="downloadBtn"
                class="download-btn"
              >
                <Icon
                  name="document-file"
                  class="w-3.5 h-3.5 sm:w-4 sm:h-4 -mt-0.5"
                />
                <span>Download Invoices</span>
              </a>
            </div>
          </div>
        </Block>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, onMounted, ref, inject } from "vue";
  import { useRoute } from "vue-router";
  
  import Block from "../checkout/modules/Block.vue";
  export default {
    name: "DownloadInvoices",
    components: {
      Block,
    },
    setup() {
      const axios = inject('axios');
      const invoiceData = ref({});
      const route = useRoute();
      const { id } = route.params;
      const downloadBtn = ref(null);
      const url = computed(
        () => `https://d3k6t6l60lmqbi.cloudfront.net/invoices_zip/${id}.zip`
      );
  
      onMounted(() => {
        getInvoicesCount();
        // downloadBtn?.value?.click();
      });
  
      const getInvoicesCount = () => 
        axios.api
          .get('/getInvoicesCount/'+ id)
          .then((res) => invoiceData.value = res.data.data)
          .catch((err) => console.log(err));
  
      return {
        id,
        url,
        downloadBtn,
        invoiceData
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .download-btn {
    @apply w-auto inline-flex items-center text-xxs sm:text-xs text-white bg-theme-secondary hover:bg-theme-secondary hover:bg-opacity-95 space-x-0.5 sm:space-x-1 tracking-wide px-2 sm:px-4 py-2.5 rounded leading-none;
  }
  </style>
  
